<template>
  <div class="pres_sumas conflex">
   
      <vtlabel style="flex:1 1 19%"
        label="Bases"
        :txt="pdat.base.value | num(2)"
        stilot="text-align:right"
      >
      </vtlabel>

      <vtlabel style="flex:1 1 19%"
        label="IVA"
        :txt="pdat.iva.value | num(2)"
        stilot="text-align:right"
      >
      </vtlabel>

      <vtlabel style="flex:1 1 19%"
        label="Suplidos"
        :txt="pdat.sup.value | num(2)"
        stilot="text-align:right"
      >
      </vtlabel>

      <div v-if="pdat.irpf">
        <vtlabel style="flex:1 1 20%"        
          label="I.R.P.F"
          :txt="pdat.irpf.value | num(2)"
          stilot="text-align:right"
        />      
      </div>
    
      <vtlabel style="flex:1 1 20%"
        label="Total"
        :txt="pdat.imp.value | num(2)"
        stilot="font-weight:bold;text-align:right;color:#01579B"
              >
       </vtlabel>
    
  </div>
</template>

<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  }
};
</script>
